import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import { between } from "polished"
import { motion } from "framer-motion"
import {
  Paragraph,
  Screen,
  Content,
  HeaderContainer,
} from "_data/global_styles"
import PageHeadline from "_components/misc/pageHeadline"
import Header from "_components/nav/header/_header"
import styled from "styled-components"
import Footer from "_components//nav/footer/_footer"
import { color_subHeading } from "_src/assets/data/global_colors"

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Screen>
        <Content>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.65 }}
          >
            <PageHeadline copy="Privacy Policy." />

            <header style={{ marginLeft: "1vw", color: "#303c6c" }}>
              <Paragraph>
                By accessing this Website, you agree to be bound by the
                following Privacy Policy that was last updated on: 10/19/2020.
              </Paragraph>
            </header>
            <div style={{ margin: "5vh 0 0 1vw" }}>
              <Question style={{ color: color_subHeading }}>
                Collecting personal information:
              </Question>
              <Answer>
                We do not collect personal information about you unless you
                voluntarily submit that information to us, via email, phone
                calls or contact forms.
              </Answer>
              <Question style={{ color: color_subHeading }}>
                Data collection:
              </Question>
              <Answer>
                We may collect, store and use the following kinds of data:
                Information about your computer and about your visits to and use
                of this Website (including your IP address, geographical
                location, browser type and version, operating system, referral
                source, length of visit, page views and Website navigation
                paths). This data is to better understand use patterns, not to
                identify any one user. We use third party services to analyze
                use patterns.
              </Answer>
              <Question style={{ color: color_subHeading }}>Cookies:</Question>
              <Answer>
                ActiveEMDR may use cookies to keep track of session names, user
                names, or actions you have taken on the website. We also utilize
                third party services that make use of cookies. Cookies are text
                files placed on your computer that can only be read by your
                internet browser. The information is used to track visitor use
                of the Website and to compile statistical reports on website
                activity. This helps us analyse data about web page traffic and
                improve this Website in order to tailor it to user needs. We
                only use this information for statistical analysis purposes and
                then the data is removed from the system.
              </Answer>
              <Question style={{ color: color_subHeading }}>
                Links to other websites
              </Question>
              <Answer>
                Our Website may contain links to other websites of interest.
                However, once you have used these links to leave this Website,
                you should note that we do not have any control over that other
                website. Therefore, we cannot be responsible for the protection
                and privacy of any information which you provide whilst visiting
                such sites and such sites are not governed by this privacy
                statement.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Communications with us
              </Question>
              <Answer>
                By providing your email address to us, you expressly consent to
                receive emails from us. We may use email to communicate with
                you, to send information that you have requested or to send
                information about other services provided by us, provided that
                we will not give your email address to another party to promote
                their products or services directly to you without your consent
                or as set forth in this Privacy Policy.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                HIPAA compliance
              </Question>
              <Answer>
                We are HIPAA compliant in that we do not collect, record, store,
                or even see Protected Health Information.
              </Answer>
              <Answer>
                The third party video communication providers that we integrate
                into this website may be required to be HIPAA compliant. Please
                refer to those providers for more information. We do not have
                access, see, or record any video communication between the
                therapist and clients. This data never touches our servers.
                Additionally, we do not see or have access to passwords used
                with these services.
              </Answer>

              <Question style={{ color: color_subHeading }}>
                Security Precautions
              </Question>
              <Answer>
                Our website is secured with the https protocol. Users should
                take reasonable precaution when creating a Session Name for our
                services. If, in the event that someone intercepts Session
                communication from this website, guesses your Session Name, or
                is able to hack into an activeEMDR session, they will not have
                any access to Protected Health Information. They will not have
                access to video conference communication. The worst case
                scenario is that they are able to see BLS speed, size, and
                repetion changes. They will not be able to identify IP
                addresses, names, or any other type of personal information.
              </Answer>
              <Question style={{ color: color_subHeading }}>
                Changes to our privacy policy
              </Question>
              <Answer>
                We keep our privacy policy under regular review and we will
                place any updates on this web page.
              </Answer>
            </div>
          </motion.div>
        </Content>
      </Screen>
      <Footer />
    </>
  )
}



const Question = styled.h2`
  font-family: montserrat;
  margin: 6vh 0 1vh 0;
  line-height: 1.4em;
  /* font-size: 22px; */
  font-size: ${between("20px", "26px", "250px", "3840px")};
`
const Answer = styled.h3`
  font-family: montserrat;
  font-size: 1em;
  font-size: ${between("16px", "19px", "250px", "3840px")};
  /* font-size: 19px; */
  font-weight: 500;
  margin: 0 0 2vh 1vw;
  line-height: 1.6em;
`
export default PrivacyPolicy
